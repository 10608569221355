import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { isString } from '../lib/predicates';

export const usePaginationFromUrl = () => {
  const router = useRouter();
  const {
    page: pageQueryParam,
    query: queryQueryParam,
    filter: filterQueryParam,
  } = router.query;
  const page = +(pageQueryParam ?? 1);
  const searchQuery: string | null = isString(queryQueryParam)
    ? queryQueryParam
    : null;
  const filter = isString(filterQueryParam) ? filterQueryParam.split(',') : [];

  // atoms:
  const setPage = useCallback(
    (p: number) => {
      const path = router.pathname;
      const query = router.query;
      query.page = `${Math.max(1, p)}`;
      router.push({
        pathname: path,
        query: query,
      });
    },
    [router]
  );
  const setSearchQuery = useCallback(
    (p: string | string[] | null) => {
      const path = router.pathname;
      const query = router.query;
      if (p || query.query) {
        query.query = `${p}`;
      }
      router.push({
        pathname: path,
        query: query,
      });
    },
    [router]
  );
  const setFilter = useCallback(
    (p: string | string[]) => {
      if (Array.isArray(p)) {
        const path = router.pathname;
        const query = router.query;
        query.filter = Array.from(p).join(',');
        router.push(
          {
            pathname: path,
            query: query,
          },
          undefined,
          { scroll: false }
        );
      }
    },
    [router]
  );

  return { page, setPage, searchQuery, setSearchQuery, filter, setFilter };
};
