import { memo, useState, FC } from 'react';

import { Box, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type IconFilterProps = {
  onChange: (categories: string[]) => void;
  filter: string[];
};
export const IconFilter: FC<IconFilterProps> = ({ onChange, filter }) => {
  const [value, setValue] = useState<string[]>(filter);

  const { t } = useTranslation();

  const icons = [
    'environment',
    'watter',
    'gov',
    'energy',
    'waste',
    'urban',
    'transport',
    'med',
    'lighting',
    'ict',
    'www',
  ];

  const onIconClick = (icon: string) => {
    setValue((prv) => {
      // todo: refactor
      if (value.includes(icon)) {
        const result = [...value].filter((current) => current !== icon);
        if (typeof onChange === 'function') {
          onChange(result);
        }
        return result;
      } else {
        const result = prv.concat(icon);
        if (typeof onChange === 'function') {
          onChange(result);
        }
        return result;
      }
    });
  };

  return (
    <Box maxW="100%" overflowX="auto" bg="#F2F2F2">
      <SimpleGrid
        pt={10}
        pb={10}
        px={6}
        spacing={{ base: 0, md: 4 }}
        columns={icons.length}
        mx="auto"
        minW="80rem"
      >
        {icons.map((icon) => {
          return (
            <Box key={icon}>
              <Box
                borderRadius="xl"
                mx="auto"
                mb={1}
                w="6rem"
                h="6rem"
                bgColor={value.includes(icon) ? '#FFF' : 'transparent'}
                _hover={{ backgroundColor: '#E2E2E2' }}
                title={t<string>(`publicProjectList:icon_filter.${icon}`)}
                onClick={() => onIconClick(icon)}
                textAlign="center"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundImage: `icon/${icon}.svg`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '50% 50%',
                  backgroundSize: '60%',
                  cursor: 'pointer',
                  textAlign: 'center',
                }}
              ></Box>
              <Box fontSize="md" textAlign="center">
                {t<string>(`publicProjectList:icon_filter.${icon}`)}
              </Box>
            </Box>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default memo(IconFilter, () => false);
