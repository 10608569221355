import Axios, { AxiosError } from 'axios';
import { QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Project, ProjectList } from '../../domain/project';

import type { UseQueryResult } from '@tanstack/react-query';

type User = any;

/**
 * Public Project Detail
 */
export const useProject = (
  projectId: string,
  config: UseQueryOptions<Project, AxiosError, Project> = {}
) => useQuery<Project, AxiosError>(
  ['public-project', projectId],
  (): Promise<User> => Axios.get(`/projects/${projectId}`),
  {
    staleTime: 10000,
    cacheTime: 5000,
    ...config,
  }
);

type UseProjectListResult = UseQueryResult<ProjectList> &
{ totalItems: number, hasMore: boolean, totalPages: number, isLoadingPage: boolean, isErrorPage: boolean };

export const useProjectList = (
  ops: { page: number, size: number, query: string | null, filter: string[] },
  config: UseQueryOptions<
    ProjectList,
    AxiosError,
    ProjectList
  > = {}
): UseProjectListResult => {
  const { page = 0, size = 10, query = '', filter = [] } = ops;

  const result = useQuery<ProjectList, AxiosError>(
    ['public-project', { page, size, query, filter }],
    (): Promise<ProjectList> =>
      Axios.get('/projects', {
        params: {
          page,
          size,
          sort: 'id,desc',
          query,
          filter: encodeURIComponent(filter.join(',')),
        },
      }),
    {
      onSuccess: (_data) => {
        // TODO: fill details cache
      },
      keepPreviousData: true,
      staleTime: 10000,
      cacheTime: 5000,
      ...config,
    }
  );

  const totalItems = result.data?.totalItems ?? 0;
  const totalPages = Math.ceil(totalItems / size);
  const hasMore = page + 1 < totalPages;
  const isLoadingPage = result.isFetching;
  const isErrorPage = result.isError;

  return {
    totalItems,
    hasMore,
    totalPages,
    isLoadingPage,
    isErrorPage,
    ...result,
  };
};

export const fetchProject = async (projectId: string): Promise<Project> => {
  const result = await Axios.get(`/projects/${projectId}`);
  console.log('result', result);

  return {
    id: 'xxx',
    projectName: 'yyy',
    projectStatus: 'PUBLISHED',
    langKey: 'cs',
  } as TODO;
};

export const prefetchProjectQuery = async (projectId: string) => {
  const queryClient = new QueryClient();
  await queryClient.prefetchQuery(['project'], () => fetchProject(projectId));
  return dehydrate(queryClient);
};

function dehydrate(_queryClient: QueryClient) {
  throw new Error('Function not implemented.');
}
