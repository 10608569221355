import { ReactNode, useEffect, useMemo, useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useFeature } from '@/hooks/useFeature';
import { debounce } from '@/lib/utils';

import type { ChangeEventHandler, FC } from 'react';

type Props = {
  children?: ReactNode;
  placeholder?: string;
  onChange: (value: string) => void;
  initialValue?: string | null;
};

export const PublicProjectSearchBox: FC<Props> = ({
  children,
  placeholder,
  onChange,
  initialValue = '',
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);
  const [debouncedChangeHandler, disposeChangeHandler] = useMemo(
    () => debounce(onChange, 500),
    [onChange],
  );

  const [isAdvancedSearchEnabled] = useFeature('search.advanced');

  const handleReset = () => {
    onChange('');
    setValue('');
  };

  useEffect(() => {
    return () => {
      disposeChangeHandler();
    };
  }, [disposeChangeHandler]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    debouncedChangeHandler(newValue);
  };

  const handleToggleAdvancedSearch = () => {
    console.log('Toggle');
  };

  const handleShowHelp = () => {
    console.log('Show Help');
  };

  const view = (
    <HStack w="full">
      <Box flex="1">
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="brand.300" />}
          />
          <Input
            size="md"
            placeholder={placeholder}
            value={value ?? ''}
            onChange={handleChange}
            focusBorderColor="brand.500"
          />
          {children}
        </InputGroup>
      </Box>
      <IconButton
        onClick={handleReset}
        disabled={!value}
        size="sm"
        variant="outline"
        colorScheme="teal"
        aria-label="Call Sage"
        icon={<CloseIcon />}
      />
    </HStack>
  );

  return isAdvancedSearchEnabled ? (
    <VStack maxW={{ base: 'full', md: '30rem' }} mx="auto" mb="4">
      {view}
      <HStack w="full" justifyContent="space-between">
        <Box>
          <Button variant="link" onClick={handleToggleAdvancedSearch}>
            {t<string>('search:use_advanced_search')}
          </Button>
        </Box>
        <Box>
          <Button variant="link" onClick={handleShowHelp}>
            {t<string>('search:help')}
          </Button>
        </Box>
      </HStack>
    </VStack>
  ) : (
    <Box maxW={{ base: 'full', md: '30rem' }} mx="auto" mb="4">
      {view}
    </Box>
  );
};

export default PublicProjectSearchBox;
