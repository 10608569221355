import { useState } from 'react';
import dynamic from 'next/dynamic';
import {
  Box,
  Button,
  HStack,
  VStack,
  Select,
  Spacer,
  Container,
  Text,
  Image,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  DataListFooter,
  DataListTile,
  DataTiles,
} from '@/components/DataList2';
import {
  Pagination,
  PaginationButtonFirstPage,
  PaginationButtonLastPage,
  PaginationButtonNextPage,
  PaginationInfo,
} from '@/components/Pagination';
import IconFilter from '@/components/Project/IconFilter';
import { usePaginationFromUrl } from '@/hooks/usePaginationFromUrl';

import { PublicProjectSearchBox } from './PublicProjectSearchBox';
import { useProjectList } from './projects.service';
import Loader from '@/components/Loader/Loader';

import type { FC } from 'react';
import type { Props as PublicProjectModalDetailProps } from './PublicProjectModalDetail';

type Props = Partial<{
  enableIconFilter: boolean;
  enablePagination: boolean;
  enableSearch: boolean;
  pageSizes: number[];
}>;

const DynamicPublicProjectModalDetail = dynamic<PublicProjectModalDetailProps>(
  () => import(/* webpackChunkName: "project_modal" */ './PublicProjectModalDetail')
    .then((mod) => mod.PublicProjectModalDetail),
  {
    ssr: false,
    loading: () => <Loader />,
  }
);

export const PublicProjectsTiles: FC<Props> = ({
  enableIconFilter = true,
  enablePagination = false,
  enableSearch = false,
  pageSizes = [],
}) => {
  const { t } = useTranslation();
  const { page, setPage, searchQuery, setSearchQuery, filter, setFilter } =
    usePaginationFromUrl();
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const [pageSize, setPageSize] = useState<number>(21);
  /* const [searchQuery, setSearchQuery] = useState<string>(null); */

  const {
    data: projects,
    totalItems,
    isLoadingPage,
    isErrorPage,
  } = useProjectList({
    page: page - 1,
    size: pageSize,
    query: searchQuery,
    filter,
  });

  const handleClickOnRow = (_idx: number, projectId: string) => {
    setSelectedId(projectId);
  };

  const handleShowMore = () => {
    setPageSize(pageSize + 20);
  };

  const handlePageSizeChange = (event: React.ChangeEvent<{ value: any }>) => {
    const parsedPageSize = parseInt(event.target.value, 10);
    if (!isNaN(parsedPageSize) && parsedPageSize) {
      setPageSize(parsedPageSize);
    }
  };

  const handleIconFilterChange = (categories: string[]) => {
    setFilter(categories);
  };

  const handleQueryChange = (value: string) => {
    setSearchQuery(value === '' ? null : value);
  };

  const handleModalClose = () => {
    setSelectedId(null);
  };

  return (
    <>
      {selectedId && <DynamicPublicProjectModalDetail projectId={selectedId} onClose={handleModalClose} />}

      {enableIconFilter && (
        <IconFilter onChange={handleIconFilterChange} filter={filter} />
      )}

      {enableSearch && (
        <PublicProjectSearchBox
          placeholder={t<string>('publicProjectList:search.placeholder')}
          onChange={handleQueryChange}
          initialValue={searchQuery}
        />
      )}
      {isErrorPage && (
        <Box textAlign="center">Zkontroluje prosím připojení k internetu</Box>
      )}

      <Container centerContent mt="2rem" maxW="8xl">
        <DataTiles borderRadius="none" mt={4}>
        {projects?.content?.map((project, idx) => (
          <DataListTile
            key={project.id || idx}
            minW="20rem"
            shadow="md"
          >
            <Box
              w="full"
              h="full"
              px={6}
              pt={8}
              mb={2}
              fontSize="xl"
              textAlign="left"
              borderTopRadius={'xl'}
              minHeight="10rem"
            >
              <VStack
                borderBottom="1px solid gray"
                h="full"
              >
                <Text as="div" flex={1} align="left" w="full">
                  {project.projectName}
                </Text>
                <HStack ml={-1} spacing={6} mb={6} justifyContent="space-between" w="full">
                  <HStack pb={4} flex={1}>
                    {project.award === 'winner' && (<Box>
                      <Image src="/icon/winner.svg" width={38} alt="Vítěz" />
                    </Box>)}
                    {project.award === 'finalist' && (<Box>
                      <Image src="/icon/finalist.svg" width={38} alt="Finalista" />
                    </Box>)}
                    {project.partnershipProject && (<Box>
                      <Image src="/icon/partner.svg" width={38} alt="Partnerský projekt" />
                    </Box>)}
                  </HStack>
                  <Text as="div" fontSize="0.9rem" pb={2} fontWeight="400" flex={1}>
                    přihlašovatel
                    <Box>
                      {(project.submitters ?? []).map((submitter, idx) => {
                        return (<Box key={submitter.id ?? idx} textTransform="uppercase" fontWeight="500">
                          {submitter.name}
                        </Box>);
                      })}
                    </Box>
                  </Text>
                </HStack>
              </VStack>
            </Box>
            <HStack w="full" px={5} pb={6} pt={4}>
              {(project.category || []).slice(0, 3).map((category) => {
                return (
                  <Box
                    key={category}
                    m={1}
                    w="2.8rem"
                    h="2.8rem"
                    title={t<string>(`publicProjectList:icon_filter.${category}`)}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundImage: `icon/${category}.svg`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '50% 50%',
                      backgroundSize: '100%',
                    }}
                  />
                );
              })}
              {(project.category || []).length > 2 &&
               (<Text
                  color="gray"
                  fontSize="1rem"
                  fontWeight="400"
                  title={project.category.slice(3).map((category) => t(`publicProjectList:icon_filter.${category}`)).join(', ')}
                >
                 +{(project.category || []).length - 3}
               </Text>)}
              <Spacer />
              <Box>
                <Button
                  borderRadius="full"
                  size="md"
                  minWidth="8rem"
                  fontSize="sm"
                  onClick={() => handleClickOnRow(idx, project.id)}
                >
                  vybrat
                </Button>
              </Box>
            </HStack>
          </DataListTile>
        ))}
      </DataTiles>
      </Container>

      {enablePagination && (
        <>
          <DataListFooter
            w="full"
            borderTopColor="transparent"
            justifyContent="space-between"
            sx={{ clear: 'both' }}
          >
            <Pagination
              isLoadingPage={isLoadingPage}
              setPage={setPage}
              page={page}
              pageSize={pageSize}
              totalItems={totalItems}
            >
              <PaginationButtonFirstPage />
              <PaginationButtonFirstPage />
              <PaginationInfo flex="1" />
              <PaginationButtonNextPage />
              <PaginationButtonLastPage />
            </Pagination>
          </DataListFooter>
          <DataListFooter
            borderTopColor="transparent"
            justifyContent="space-between"
            w="full"
          >
            <Button onClick={() => handleShowMore()} variant="@primary">
              {t<string>('publicProjectList:pagination.show_more')}
            </Button>
            <Spacer />
            <Select
              value={pageSizes.includes(pageSize) ? pageSize : -1}
              width={'auto'}
              onChange={handlePageSizeChange}
            >
              {pageSizes.includes(pageSize) ? null : (
                <option value={-1} key={-1}>
                  {t<string>('publicProjectList:pagination.select_page_size')}
                </option>
              )}
              {pageSizes.map((pageSize) => (
                <option value={pageSize} key={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Select>
          </DataListFooter>
        </>
      )}
    </>
  );
};

export default PublicProjectsTiles;
